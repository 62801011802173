import styled from 'styled-components'

import {theme} from '@settleindex/react2'

import {collapsedPrimaryNavWidth, expandedPrimaryNavWidth} from '../../page/consts'
import {LabelCol} from './MenuSection'

interface Props {
  expanded: boolean
}

export const PrimaryNavLayout = styled.div<Props>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;

  width: ${(props: Props) => (props.expanded ? expandedPrimaryNavWidth : collapsedPrimaryNavWidth)}px;
  height: 100vh;

  z-index: 10;
  overflow: hidden;
  transition: width ${theme.timing.fast} ease-in;

  background-color: ${theme.colors.primary.surfaceAccentDeeper};

  & ${LabelCol} {
    transition: opacity ${theme.timing.fast} ease-in;
    opacity: ${(props: Props) => (props.expanded ? 1 : 0)};
  }
`
