import {ApolloClient, ApolloLink, InMemoryCache, createHttpLink} from '@apollo/client'

import {authLink} from './authLink'
import {dataIdFromObject} from './dataIdFromObject'
import {errorLink} from './errorLink'
import {typePolicies} from './typePolicies'

interface Args {
  getToken: () => string | void
  getUserId: () => Promise<string | void>
  graphUrl: string
}

export const getApolloClient = ({getToken, getUserId, graphUrl}: Args) => {
  const httpLink = createHttpLink({uri: graphUrl})
  const authenticatedLink = authLink(getToken, getUserId).concat(httpLink)

  const link = ApolloLink.from([errorLink, authenticatedLink])

  const cache = new InMemoryCache({dataIdFromObject, typePolicies})

  return new ApolloClient({link, cache})
}
