import React from 'react'
import {Link as RRLink, type LinkProps as RRLinkProps} from 'react-router-dom'

import {PrimaryText} from '@settleindex/react2'

interface LinkProps extends RRLinkProps {
  top?: boolean
  underline?: boolean
}

export const Link: React.FC<LinkProps> = ({top, underline, ...props}) => {
  const underlineStyle = underline ? {textDecoration: 'underline'} : {textDecoration: 'none'}
  const style = {...props.style, ...underlineStyle}

  const child =
    typeof props.children === 'string' ? (
      <PrimaryText style={underlineStyle}>{props.children}</PrimaryText>
    ) : (
      props.children
    )

  const onClick = top ? () => window.scrollTo(0, 0) : undefined

  return (
    <RRLink {...props} onClick={onClick} style={style}>
      {child}
    </RRLink>
  )
}
