import {ChartIcon, CompareIcon, DashboardIcon, FoldersIcon, SettingsIcon, TeamIcon} from '@settleindex/react2'
import {primaryNavigationTestIds} from '@settleindex/testids'

import {paths} from '../../routing'
import {PrimaryMenuItem} from './PrimaryMenuItem'

export const getDisputeMenuItems = ({
  disputeId,
  disputeReadonly,
  // disputeTitle,
}: {
  disputeId?: string
  disputeReadonly?: boolean
  // disputeTitle?: string
}): PrimaryMenuItem[] => {
  const disputesMenuItem = {
    icon: <FoldersIcon size={16} />,
    key: primaryNavigationTestIds.disputeList,
    label: 'Cases',
    linkPath: paths.disputes(),
  }

  if (!disputeId) {
    return [disputesMenuItem]
  }

  const readonlyMenuItems = [
    disputesMenuItem,
    {
      key: primaryNavigationTestIds.disputeTitle,
      // label: (
      //   <PrimaryTextOnAccent style={{paddingInline: 5, paddingBlock: 10, whiteSpace: 'break-spaces'}}>
      //     {disputeTitle}
      //   </PrimaryTextOnAccent>
      // ),
      // linkPath: paths.dispute(disputeId),
      // isActive: false,
      // hasHoverEffect: false,
      isGap: true,
    },
    {
      icon: <DashboardIcon size={16} />,
      key: primaryNavigationTestIds.dashboard,
      label: 'Dashboard',
      linkPath: paths.dispute(disputeId),
    },
    {
      icon: <ChartIcon size={16} />,
      key: primaryNavigationTestIds.disputeHistory,
      label: 'History',
      linkPath: paths.disputeMetrics(disputeId),
    },
    {
      icon: <CompareIcon size={16} />,
      key: primaryNavigationTestIds.compare,
      label: 'Compare',
      linkPath: paths.versionCompare(disputeId),
    },
    // {
    //   icon: <CoinsIcon size={16} />,
    //   key: primaryNavigationTestIds.offers,
    //   label: 'Offers',
    //   linkPath: paths.disputeEvents(disputeId),
    // },
  ]

  if (disputeReadonly) {
    return readonlyMenuItems
  }

  return [
    ...readonlyMenuItems,
    {
      icon: <TeamIcon size={16} />,
      key: primaryNavigationTestIds.team,
      label: 'Team',
      linkPath: paths.disputeSharingList(disputeId),
    },
    {
      icon: <SettingsIcon size={16} />,
      key: primaryNavigationTestIds.settings,
      label: 'Case Settings',
      linkPath: paths.disputeSettings(disputeId),
    },
  ]
}
