import * as React from 'react'

import {A} from '@settleindex/react2'

export const TosLink: React.FC<{}> = () => {
  return (
    <A href="https://settleindex.com/terms/" variant={'secondary'}>
      Terms of Service
    </A>
  )
}

export const PrivacyLink: React.FC<{}> = () => {
  return (
    <A href="https://settleindex.com/privacy/" variant={'secondary'}>
      Privacy Policy
    </A>
  )
}
