import * as React from 'react'

import {Center, FlexCol, H1, PrimaryButton} from '@settleindex/react2'

interface AmbulanceProps {
  message?: string
  to?: string
}

export const Ambulance: React.FC<AmbulanceProps> = ({message, to}) => {
  const onClick = React.useCallback(() => (to ? window.location.replace(to) : window.location.reload()), [to])

  return (
    <Center>
      <FlexCol align="center" gap="xl">
        <H1>{message ?? 'The app needs to be reloaded'}</H1>
        <PrimaryButton onClick={onClick}>Reload</PrimaryButton>
      </FlexCol>
    </Center>
  )
}
