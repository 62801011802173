import * as React from 'react'
import styled from 'styled-components'

import {contentWidth} from '../page/consts'

interface Props {
  style?: React.CSSProperties
  testId?: string
}

export const ContentLayout: React.FC<React.PropsWithChildren<Props>> = ({children, style, testId}) => (
  <Layout data-test-id={testId} style={style}>
    <ContentWidth>{children}</ContentWidth>
  </Layout>
)

const Layout = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding: 0px 60px 60px 60px;
`

export const ContentWidth = styled.div`
  width: 100%;
  max-width: ${contentWidth}px;
  margin: 0 auto;
`
