import * as React from 'react'

import {FlexRow, FlexRowCenter, H1, PrimaryButton, VSpaceSmall} from '@settleindex/react2'

import {LoginPageFrame} from './LoginPageFrame'
import {Logo} from './Logo'
import {useUser} from './useUser/useUser'

export const LogoutPage: React.FC = () => {
  const {logout} = useUser()

  React.useEffect(() => {
    logout()
  }, [logout])

  return (
    <LoginPageFrame>
      <FlexRowCenter>
        <Logo />
      </FlexRowCenter>
      <FlexRowCenter>
        <H1 style={{marginTop: 40}}>SettleIndex</H1>
      </FlexRowCenter>

      <FlexRow py="xxl">
        <PrimaryButton onClick={logout}>Signing you out...</PrimaryButton>
        <VSpaceSmall />
      </FlexRow>
    </LoginPageFrame>
  )
}
