import {atom, useRecoilState} from 'recoil'

const debugPanelVisibleState = atom<boolean>({
  default: false,
  key: 'debugPanelState',
})

const debugPanelContentState = atom<any>({
  default: null,
  key: 'debugPanelContentState',
})

/**
 * See JSONView:
 * When set to true, all nodes will be collapsed by default. Use an integer value to collapse at a particular depth.
 */
const debugPanelCollapseState = atom<number | boolean>({
  default: undefined,
  key: 'debugPanelCollapse',
})

export const useDebugPanel = () => {
  const [isDebugPanelVisible, setIsDebugPanelVisible] = useRecoilState(debugPanelVisibleState)
  const [debugPanelContent, setDebugPanelContent] = useRecoilState(debugPanelContentState)
  const [debugPanelCollapse, setDebugPanelCollapse] = useRecoilState(debugPanelCollapseState)

  const toggleDebugPanel = () => {
    setIsDebugPanelVisible((val) => !val)
  }

  return {
    debugPanelCollapse,
    debugPanelContent,
    isDebugPanelVisible,
    setDebugPanelCollapse,
    setDebugPanelContent,
    setIsDebugPanelVisible,
    toggleDebugPanel,
  }
}
