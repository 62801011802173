import * as React from 'react'
import styled from 'styled-components'

import {theme} from '@settleindex/react2'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  & .ant-form-item-explain.ant-form-item-explain-error {
    padding-bottom: 0;
  }

  & .pad-on-error .ant-form-item-has-error {
    padding-bottom: 20px;
  }
`

const Frame = styled.div`
  width: 464px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;

  background-color: white;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 3px;
`

export const LoginPageFrame: React.FC<React.PropsWithChildren<unknown>> = ({children}) => (
  <Wrapper>
    <Frame>{children}</Frame>
  </Wrapper>
)
