import React, {Suspense} from 'react'

import {Drawer} from '@settleindex/react2'

import {useUser} from '../user/useUser/useUser'
import {useDebugPanel} from './useDebugPanel'

const DebugPanel = React.lazy(() => import('./DebugPanel'))

export const DebugSection: React.FC = () => {
  const {debugPanelContent, isDebugPanelVisible, setIsDebugPanelVisible} = useDebugPanel()
  const {isAdmin} = useUser()

  return (
    <>
      {isAdmin && debugPanelContent && (
        <Drawer
          escCloses
          maskClose
          onClose={() => setIsDebugPanelVisible(false)}
          open={isDebugPanelVisible}
          placement="left"
          width={830}
          zIndex={2222}
        >
          {isDebugPanelVisible && (
            <Suspense fallback={<></>}>
              <DebugPanel />
            </Suspense>
          )}
        </Drawer>
      )}
    </>
  )
}
