import * as React from 'react'

import {Modal} from '@settleindex/react2'

import {captureException} from '../captureException'
import {useMeQuery} from '../graphQLTypes'
import {CompleteRegistrationContainer} from './CompleteRegistrationContainer'

export const EnforceActionContainer: React.FC<React.PropsWithChildren> = () => {
  const {data, error} = useMeQuery()
  if (data?.me.user.completeProfileRequired) {
    return (
      <Modal escCloses={false} maskClose={false} open showClose={false} width={580}>
        <CompleteRegistrationContainer />
      </Modal>
    )
  }

  if (data?.me.user.reloadPageSuggested) {
    document.location.reload()

    return <></>
  }

  if (error) {
    captureException(error)
  }

  return <></>
}
