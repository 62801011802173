import * as React from 'react'
import {useIdleTimer} from 'react-idle-timer'

import {hourToMillisecond, minuteToMillisecond} from '@settleindex/domain'
import {message} from '@settleindex/react2'

import {useUser} from '../useUser/useUser'
import {useAutoLogout} from './useAutoLogout'

const inactiveTimeoutMs = hourToMillisecond(1)
const promptTimeoutMs = minuteToMillisecond(10)

export const AutoLogout: React.FC = () => {
  const user = useUser()

  const onIdle = React.useCallback(() => {
    message.info('You have been logged out due to inactivity', minuteToMillisecond(0.5))
    user.logout()
  }, [user])

  const {checkIdleAfterSleep} = useAutoLogout({inactiveTimeoutMs, onIdle})

  const {isPrompted} = useIdleTimer({
    crossTab: true,
    onAction: checkIdleAfterSleep,
    onIdle,
    promptBeforeIdle: promptTimeoutMs,
    stopOnIdle: true,
    throttle: 5000,
    timeout: inactiveTimeoutMs,
  })

  React.useEffect(() => {
    const checkPromptedInterval = window.setInterval(() => {
      if (!isPrompted()) {
        return
      }

      message.info(`For your security you will be logged out soon if you're inactive.`, minuteToMillisecond(0.5))
    }, 1000)

    return () => window.clearInterval(checkPromptedInterval)
  }, [isPrompted])

  return <></>
}
