import styled from 'styled-components'

import {theme} from '@settleindex/react2'

import {secondaryNavHeight} from './secondaryNavHeight'

export const SecondaryNavLayout = styled.div`
  background-color: white;
  height: ${secondaryNavHeight}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding-left: 60px;
  border-bottom: 1px solid ${theme.colors.borderColor};

  & > div {
    padding: 0;
    width: 100%;
  }
`
